@font-face {
  font-family: 'Sofija';
  src: local('Sofija'), url(./fonts/Sofija.ttf) format('truetype');
}

#sam-stone-page {
    margin: 0 120px 80px 120px;
}

@media (max-width: 600px) {
    #sam-stone-page {
        margin: 10px;
        margin-top: 0px;
    }
}

:root {
    --titles: #943616;
}
/**********ANIMATIONS**********/
@keyframes rotate {
    0% {
        transform: rotate(10deg);
    }
    50% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(10deg)
    }
}

@keyframes drop {
    100% {
        transform: translateY(0px);
    }
}

#loading h3{
    color: black;
    font-family: 'Arvo', sans-serif;
    font-size: 30px;
}

#loading {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

@media (max-width: 600px) {
    #loading h3 {
        font-size: 20px;
    }
}
