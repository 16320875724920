.dance-reel {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#youtube {
    margin-top: 90px;
    border: 58px solid transparent;
    border-image: url(../../assets/frame.png) 16% 20% round;
    border-image-width: 57.5px;
}


@media (max-width: 600px) {
    #youtube {
        width: 100%;
        border: 30px solid transparent;
        border-image: url(../../assets/frame.png) 23.5% 18% round;
        margin-top: 40px;
    }
}
