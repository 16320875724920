
h1 {
    font-family: 'Sofija', cursive;
}

h3 {
    font-family: 'Arvo', serif;
    margin: 2px, 0px;
    color: var(--titles);
    font-size: 16px;
    font-weight: 600;
}

h2 {
    font-family: 'Arvo', serif;
    margin: 2px, 0px;
    color: var(--titles);
    font-size: 24px;
    font-weight: 600;
}

h6 {
    font-family: 'Arvo', serif;
}

.sam-creates-container {
    margin-top: 90px;
}


.bandcamp {
    border: 0;
    width: 350px;
    height: 555px;
    margin: 20px;
}

.project {
    display:grid;
    grid-template-columns: 320px 1fr;
    grid-template-rows: 10% 1fr 10%;
    margin: 20px 0px;
}

.project-image {
    grid-column-start: 1;
    height: 350px;
    border: 4px double black;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.project-image img {
    height: 100%;
}

.project-info {
    grid-column-start: 2;
    margin-left: 25px;
}

.sam-music {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 40px;
}

.sam-music-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

#music-headline {
    margin-left: 20px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    margin-top:300px;
}

.carousel-control-next-icon {
    margin-left: 15px;
}

.carousel-control-prev-icon {
    margin-right: 15px;
}

.carousel-indicators {
    display: none;
}

#moon_creates {
    grid-column-start: 1;
    width: 80%;
}

.btn.btn-outline-dark {
    border: 4px double black;
}

.btn.btn-outline-dark h5{
    margin: 5px;
}

@media (max-width: 1100px) {
    .project-image {
        height: 200px;
    }

    .project {
        grid-template-columns: 20% 1fr;
    }

    .carousel-control-next-icon {
        margin-left: 10px;
        margin-top: 170px;
    }

    .carousel-control-prev-icon {
        margin-right: 10px;
        margin-top: 170px;
    }

    .sam-music, .sam-music-container {
        flex-direction: column;
        align-items: center;
    }

    .bandcamp {
        border: 0;
        width: 300px;
        height: 475px;
        margin: 20px;
    }

}

@media (max-width: 930px) {


    .project-image {
        height: 200px;
    }

    .carousel-control-next-icon {
        margin-left: 8px;
        width: 15px;
    }

    .carousel-control-prev-icon {
        margin-right: 8px;
        width: 15px;

    }
}

@media (max-width: 745px) {
    .carousel-control-next-icon {
        margin-left: 5px;
        width: 10px;
    }

    .carousel-control-prev-icon {
        margin-right: 5px;
        width: 10px;

    }



}

@media (max-width: 580px) {
    .carousel-control-next-icon {
        margin-left: 3px;
        width: 7px;
    }

    .carousel-control-prev-icon {
        margin-right: 3px;
        width: 7px;

    }



}

@media (max-width: 850px) {

    .project {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .project-image {
        margin: 10px auto;
        width: 80%;
    }

    .project-info {
        margin-left: 0px;
    }

    .project-info h3, .project-info h6 {
        font-size: 14px;
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        margin-top: 0px;
        filter: invert(1)
    }

    .carousel-control-next-icon {
        margin-left: 8px;
        width: 15px;
    }

    .carousel-control-prev-icon {
        margin-right: 8px;
        width: 15px;
    }

    .sam-creates-container {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
    }

    .sam-creates-container h1 {
        font-size: 30px;
    }
}
