.adult-teach, .child-teach, .teaching-philosophy {
    margin-top: 90px;
    font-family: 'Arvo', serif;
}

.adult-teach h3, .child-teach h3 {
    font-family: 'Arvo', serif;
    margin: 2px, 0px;
    color: var(--titles);
    font-size: 16px;
    font-weight: 600;
}

.adult-teach p, .child-teach p {
    line-height: 150%;
}

#mail-link {
    text-align: center;
}

#mail-link a {
    font-size: 22px;
}

.adult-teach-photo {
    width: 50%;
    float: right;
    margin: 8px;
    border: 2px solid rgb(211, 211, 211, .7);
}

.adult-teach hr {
    text-align: left;
    margin-right: 53%;
}

.teaching-philosophy {
    padding-bottom: 50px;
}

#one-on-one {
    width: 100%;
}

.child-teach-photo {
    float: left;
    margin-right: 16px;
    border: 2px solid rgb(211, 211, 211, .7);
    width: 50%;
}

.adult-teach a, .child-teach a {
    font-size: 16px;
}

#class-button {
    background-color: gold;
    padding: 10px;
    border: 2px solid rgb(211, 211, 211, .7);
}

#button-container {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.teaching-philosophy {
    background-image: url("../../assets/closechain.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.teaching-philosophy .wp-block-file {
    margin-left: 4px;
    text-decoration: underline;
    padding-top: 10px;
}

@media (max-width: 950px) {

    .teaching-philosophy {
        background-image: url("../../assets/closechain.jpg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
    }

}


@media (max-width: 600px) {

    .teaching-philosophy {
        background-image: none;
    }

    .adult-teach, .child-teach, .teaching-philosophy {
        margin-top: 20px;
    }

    .adult-teach-photo {
        float: none;
        width: 100%;
        margin: auto;
    }

    .child-teach-photo {
        width: 57%;
        margin-right: 4px;
    }

    .adult-teach h3, .child-teach h3, .teaching-philosophy h3 {
        font-size: 14px;
        text-align: center;
        margin-top: 8px;
    }

    .adult-teach p {
        font-size: 14px;
        text-align: center;
    }

    .teaching-philosophy p {
        font-size: 15px;
        text-align: center;
    }
    .child-teach p {
        font-size: 13px;
        text-align: left;
    }

    .adult-teach p:last-of-type, .child-teach p:last-of-type {
        margin-bottom: 5px;
    }

    .adult-teach hr, .child-teach hr {
        margin: 8px 0px;
    }

    .adult-teach #mail-link {
        margin-bottom: 80px;
    }

    #mail-link a {
        font-size: 18px;
    }

    #button-container {
        margin-top: 10px;
        text-align: center;
    }

    #button-container h6 a{
        font-size: 14px;
    }

    .download-cv {
        text-align: center;
        font-size: 20px;
    }
}
