.navbar, .nav {
    background-color: #ffffff;
    display: grid;
    grid-template-columns: 30% 1fr;
}

.nav-bar a {
    color: black;
    text-transform: uppercase;
    font-family: 'Arvo', serif;
    font-weight: 400;
    font-size: 14px;
}

.nav-bar div.navlink {
    margin: 0px 5px;
    display: inline;
}

.nav-bar div.navlink:last-of-type {
    margin: 0px 0px;
}

.nav-bar div.navlink:first-of-type {
    margin-left: -8px;
}

a.nav-link {
    padding-left: 0px;
}

.navbar-light .navbar-nav .nav-link {
    color: black;
    text-align: center;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: gold;
}

.navbar-light .navbar-nav .nav-link.active {
    color: gold;
}

#brand {
    font-family: 'Sofija', serif;
    font-size: 50px;
    color: black;
    margin-top: 10px;
    line-height: 60%;
}

#responsive-navbar-nav.navbar-collapse.collapse {
    grid-column-start: 2;
    justify-content: flex-end;
}

div.mr-auto.navbar-nav {
    margin-right: -5px!important
}

.navbar-toggler, .navbar-toggler.collapsed {
    margin-right: 20px;
    margin-top: 5px;
}

@media (max-width: 1200px) {
    .navbar, .nav {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    #brand {
        margin-bottom: 10px;
    }
}

@media (max-width: 991px) {
    .navbar, .nav {
        flex-direction: row;
        justify-content: flex-start;
    }

    .nav-bar div.navlink:last-of-type, .nav-bar div.navlink:first-of-type {
        margin: 0px 5px;
    }

    .navbar-toggler, .navbar-toggler.collapsed {
        margin-right: 0px;
    }

    #brand {
        margin-right: 10px;
    }
}

@media (max-width: 640px) {
    #brand {
        font-size: 30px;
        margin-right: 5px;
    }
}


@media (max-width: 600px) {
    #brand {
        margin-left: 0px;
        font-size: 30px;
    }

    .nav-bar {
        display: flex;
        align-items: center;
    }

    .navbar-toggler, .navbar-toggler.collapsed {
        margin-top: 0px;
        margin-left: 5px;
    }
}

a.dropdown-item {
    text-align: center;
    background-color: white;
}

a.dropdown-item.active {
    text-align: center;
    background-color: white;
    color: gold;
}

.dropdown-item.active {
    background-color: white;
}
