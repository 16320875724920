
/**********CONTACT**********/
.contact-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transform: translateY(-470px);
    overflow: hidden;
    animation: drop 1.5s;
    animation-fill-mode: forwards;
    margin-left: 15px;
}

.contact-container h4 {
    font-family: 'Baskerville', serif;
    font-size: 3.5vh;
}

.contact-container a {
    font-family: 'Baskerville', serif;
    font-size: 2.3vh;
}

#contact-envelope, #contact-instagram {
    font-size: 4vh;
}

.contact-info {
    margin-top: 8%;
}

#moon_contact {
    width: 30%;
    margin-right: 5px;
}

@media (max-width: 600px) {
    .contact-container {
        flex-direction: column;
    }

    #moon_contact {
        width: 50%;
        margin-right: 5px;
    }

    .contact-container h4 {
        font-family: 'Baskerville', serif;
        font-size: 3vh;
        text-align: center;
    }

    .contact-info a {
        font-size: 20px;
    }

}
