.current-projects h2{
    font-family: 'Baskerville', sans-serif;
    text-align: center;
    font-size: 1.5vw;
}

.current-projects a {
    text-decoration: underline;
}

.current-projects {
    margin-top: 90px;
}

.current-project-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ticket-link {
    border: 1px solid black;
    padding: 6px;
    border-radius: 5px;
    font-size: 26px;
}

.ticket-link a {
    text-decoration: none;
}

.current-project-info img {
    width: 45%;
    border: 2px solid rgb(211, 211, 211, .7);
}

.no-events img {
    width: 85%;
    margin-top: 10px;
}

.no-events h1 {
    font-family: 'Baskerville', serif;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0px;
    font-size: 2.5vw;
}

.no-events {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 600px) {
    .no-events h1 {
        font-size: 22px;
        margin-top: 10px;
    }

    .current-projects h2, .current-projects a {
        font-size: 16px;
    }

    .current-projects {
        margin-top: 40px;
    }
}
